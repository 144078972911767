import packageJson from '../../package.json';
import { AppEnvironment } from './environment.model';

export const environment: AppEnvironment = {
    production: false,
    apiUrl: 'https://dev-msis-api.azurewebsites.net/api/v1',
    applicationName: packageJson.name,
    version: packageJson.version,
    description: packageJson.description,
    namespace: packageJson.version,
    versionText: packageJson.versionText,
    outgoingRingTone: 'https://msisstorageaccountdev.blob.core.windows.net/general/ringtone.mp3',
    environment: 'dev',
    pageTitle: 'MSIS INC',
    canDebug: true,
};
