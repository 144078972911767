import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { LookupModel } from '../model/lookup.model';

@Injectable({ providedIn: 'root' })
export class LookupService {
    constructor(private httpUtility: HttpUtility) {}

    getLoanSystems(): Observable<LookupModel[]> {
        return this.httpUtility.get<LookupModel[]>(`${environment.apiUrl}/lookup/loan-systems`);
    }

    getRoles(): Observable<LookupModel[]> {
        return this.httpUtility.get<LookupModel[]>(`${environment.apiUrl}/lookup/roles`);
    }

    getLoanSectionTypes(): Observable<LookupModel[]> {
        return this.httpUtility.get<LookupModel[]>(`${environment.apiUrl}/lookup/loan-section-types`);
    }
}
