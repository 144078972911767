{
  "name": "msis-inc",
  "version": "2.0.1",
  "description": "Msis Inc",
  "versionText": "versionText",
  "scripts": {
    "ng": "node ./node_modules/@angular/clie/bin/ng",
    "start": "ng serve --open  --configuration=dev",
    "start-local": "ng serve --open  --configuration=local",
    "build": "ng build",
    "build:prod": "ng build --configuration=prod",
    "build:stage": "ng build --configuration=stage",
    "build:dev": "ng build --configuration=dev",
    "test": "ng test",
    "lint": "eslint -c .eslintrc.js --ext .ts src/**",
    "e2e": "ng e2e",
    "rtl": "webpack --config webpack-rtl.config.js",
    "ps": "Set-ExecutionPolicy -Scope Process -ExecutionPolicy Bypass",
    "angular-upgrade": "ng update @angular/core@18 @angular/cli@18 --force"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.2",
    "@angular/cdk": "^18.0.2",
    "@angular/common": "^18.0.2",
    "@angular/compiler": "^18.0.2",
    "@angular/core": "^18.0.2",
    "@angular/forms": "^18.0.2",
    "@angular/localize": "^18.0.2",
    "@angular/platform-browser": "^18.0.2",
    "@angular/platform-browser-dynamic": "^18.0.2",
    "@angular/router": "^18.0.2",
    "@auth0/angular-jwt": "^5.2.0",
    "@fullcalendar/core": "^6.1.14",
    "@twilio/voice-sdk": "^2.11.1",
    "angular-jwt": "^0.1.11",
    "bootstrap": "^5.3.3",
    "file-saver": "^2.0.5",
    "global": "^4.4.0",
    "howler": "^2.2.4",
    "ical.js": "^2.0.1",
    "ics": "^3.7.5",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "ng2-pdf-viewer": "^10.2.2",
    "ngx-cookie-service": "^18.0.0",
    "ngx-image-zoom": "^3.0.0",
    "ngx-img-zoom": "^4.1.2",
    "ngx-logger": "^5.0.12",
    "ngx-mask": "^17.0.8",
    "perfect-scrollbar": "^1.5.5",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.0",
    "process": "^0.11.10",
    "quill": "2.0.2",
    "rxjs": "^7.8.1",
    "signature_pad": "^5.0.2",
    "tslib": "^2.6.3",
    "tui-calendar": "^1.15.3",
    "twilio-client": "^1.15.1",
    "util": "^0.12.5",
    "xlsx": "^0.18.5",
    "zone.js": "^0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.3",
    "@angular-eslint/builder": "18.0.1",
    "@angular-eslint/eslint-plugin": "^18.0.1",
    "@angular-eslint/eslint-plugin-template": "^18.0.1",
    "@angular-eslint/schematics": "18.0.1",
    "@angular-eslint/template-parser": "18.0.1",
    "@angular/cli": "^18.0.3",
    "@angular/compiler-cli": "^18.0.2",
    "@angularclass/hmr": "^3.0.0",
    "@types/lodash": "^4.17.5",
    "@types/node": "^20.14.2",
    "@typescript-eslint/eslint-plugin": "^7.13.0",
    "@typescript-eslint/parser": "^7.13.0",
    "codelyzer": "^6.0.2",
    "cpx": "^1.5.0",
    "eslint": "^9.4.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-angular": "^4.1.0",
    "eslint-plugin-css-modules": "^2.12.0",
    "eslint-plugin-html": "^8.1.1",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.9",
    "eslint-plugin-json": "^4.0.0",
    "eslint-plugin-node": "^11.1.0",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-rxjs": "^5.0.3",
    "gzipper": "^7.2.0",
    "prettier": "3.3.3",
    "prettier-eslint": "^16.3.0",
    "prettier-stylelint": "^0.4.2",
    "pretty-quick": "^4.0.0",
    "protractor": "^7.0.0",
    "ts-cacheable": "^1.0.10",
    "ts-node": "^10.9.2",
    "typescript": "5.4.5",
    "webpack-cli": "^5.1.4",
    "webpack-messages": "^2.0.4"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "lint-staged": {
    "*.{ts,js,json,scss,md,html}": [
      "prettier --write",
      "git add"
    ]
  },
  "cli": {
    "packageManager": "yarn"
  },
  "packageManager": "yarn@4.2.2"
}
