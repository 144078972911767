import { Injectable } from '@angular/core';
import { CallStatusModel } from '@app/communication/model/call-status.model';
import { CallModel } from '@app/communication/model/call.model';
import { CallerIdModel } from '@app/communication/model/caller-id.model';
import { TwiMLResult } from '@app/communication/model/twiml-result.model';
import { VoiceMailModel } from '@app/communication/model/voice-mail.model';
import { environment } from '@environments/environment';
import { StringResponse } from '@shared/model/string-response';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PhoneCallService {
    constructor(private httpUtility: HttpUtility) {}

    getToken(): Observable<string> {
        return this.httpUtility.get(`${environment.apiUrl}/phone-calls/token`);
    }

    connect(callModel: CallModel): Observable<TwiMLResult> {
        return this.httpUtility.post(`${environment.apiUrl}/phone-calls/connect`, callModel);
    }
    callStatus(callId: string): Observable<CallStatusModel> {
        return this.httpUtility.get(`${environment.apiUrl}/phone-calls/call-status?callSid=${callId}`);
    }
    validateCallerId(callerIdModel: CallerIdModel): Observable<StringResponse> {
        return this.httpUtility.post(`${environment.apiUrl}/phone-calls/validate-caller-id`, callerIdModel);
    }
    sendVoiceMail(voiceMailModel: VoiceMailModel): Observable<StringResponse> {
        return this.httpUtility.post(`${environment.apiUrl}/phone-calls/voice-mail`, voiceMailModel);
    }
}
