import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Bearertoken } from '@shared/authentication/model/bearer-token-model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HttpUtility {
    headers: HttpHeaders;
    httpOptions = {};

    constructor(private readonly httpClient: HttpClient) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Accept', 'application/json');
        this.headers = this.headers.append('Content-Type', 'application/json, text/html');
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers = this.headers.append('Access-Control-Allow-Credentials', 'true');
        this.headers = this.headers.append('X-Requested-With', 'XMLHttpRequest');
        this.headers = this.headers.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,OPTIONS');
        this.headers = this.headers.append('Cache-Control', 'no-cache,no-store,must-revalidate,post-check=0,pre-check=0');

        this.headers = this.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,Content-Type');
        this.httpOptions = {
            headers: this.headers,
            pragma: 'no-cache',
            Expires: '0',
            params: new HttpParams(),
        };
    }

    get<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(url, this.httpOptions).pipe(
            catchError((response) => {
                return this.handleError(response);
            }),
        );
    }

    getRefreshToken(id?: string): Observable<Bearertoken> {
        return this.post(`${environment.apiUrl}/refresh-token?id=${id}`, '');
    }

    post<T>(url: string, body: any | string): Observable<T> {
        return this.httpClient.post<T>(url, JSON.stringify(body), this.httpOptions).pipe(
            catchError((response) => {
                return this.handleError(response);
            }),
        );
    }
    postFile<T>(url: string, body: FormData): Observable<T> {
        const httpHeaders = new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Disposition': 'multipart/form-data',
        });

        const options = {
            headers: httpHeaders,

            params: new HttpParams(),
        };
        // const options2 = {};
        return this.httpClient.post<T>(url, body, options).pipe(
            catchError((response) => {
                return this.handleError(response);
            }),
        );
    }

    put<T>(url: string, body: any | string): Observable<T> {
        return this.httpClient.put<T>(url, JSON.stringify(body), this.httpOptions).pipe(
            catchError((response) => {
                return this.handleError(response);
            }),
        );
    }
    patch<T>(url: string, body: any | string): Observable<T> {
        return this.httpClient.patch<T>(url, JSON.stringify(body), this.httpOptions).pipe(
            catchError((response) => {
                return this.handleError(response);
            }),
        );
    }
    delete<T>(url: string, body?: any | string): Observable<T> {
        this.httpOptions['body'] = body;
        return this.httpClient.delete<T>(url, this.httpOptions).pipe(
            catchError((response) => {
                return this.handleError(response);
            }),
        );
    }

    private handleError(error: Response | any) {
        console.error(error);
        return throwError(error);
    }
}
