import { OnInit, OnDestroy, HostListener, Directive } from '@angular/core';
import { LocalStorageService } from '@core/service/localStorage.service';
import { LoggerService } from '@core/service/logger.service';
import { LookupService } from '@core/service/lookup.service';
import { ServiceInjector } from '@core/service/service-injector.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { ComponentCanDeactivate } from './pending-changes-guard.component';

@Directive()
export abstract class BaseComponentDirective implements OnInit, OnDestroy, ComponentCanDeactivate {
    loading = false;
    logger: LoggerService;
    messageService: MessageService;
    confirmService: ConfirmationService;
    localStorageService: LocalStorageService;
    lookupService: LookupService;
    protected onDestroy$ = new Subject();

    protected constructor() {
        this.logger = ServiceInjector.Injector.get(LoggerService);
        this.messageService = ServiceInjector.Injector.get(MessageService);
        this.confirmService = ServiceInjector.Injector.get(ConfirmationService);
        this.lookupService = ServiceInjector.Injector.get(LookupService);
        this.localStorageService = ServiceInjector.Injector.get(LocalStorageService);
    }
    @HostListener('window.beforeunload')
    @HostListener('window.pagehide')
    canDeactivate(): boolean | Observable<boolean> {
        return true;
    }
    ngOnInit(): void {
        this.logger.log('component created', this);
    }
    ngOnDestroy(): void {
        this.onDestroy$.next(null);
    }
}
