import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    userValue = new BehaviorSubject(this.loggedUser);
    isLoggedIn = new BehaviorSubject<boolean>(this.loggedIn);
    get loggedUser(): any | unknown {
        return localStorage.getItem('user');
    }
    set loggedUser(value) {
        this.userValue.next(value); // this will make sure to tell every subscriber about the change.
        localStorage.setItem('user', value);
    }

    set loggedIn(value: boolean) {
        this.isLoggedIn.next(value);
        localStorage.setItem('loggedIn', JSON.stringify(value));
    }
    get loggedIn(): boolean {
        return JSON.parse(localStorage.getItem('loggedIn')) as boolean;
    }
}
