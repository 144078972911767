import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
export declare class LogInterface {
    appName: string;
}

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    env: string;
    canDebug: boolean;

    constructor(private logger: NGXLogger) {}

    trace(message: any | string, source: any | string, ...additional: any[]): void {
        this.logger.trace(message, source, additional);
    }

    debug(message: any | string, source: any | string, ...additional: any[]): void {
        this.logger.debug(message, source, additional);
    }

    info(message: any | string, source: any | string, ...additional: any[]): void {
        this.logger.info(message, source, additional);
    }
    log(message: any | string, source: any | string, ...additional: any[]): void {
        this.logger.debug(message, source, additional);
    }
    message(message: any | string, source: any | string, ...additional: any[]): void {
        this.logger.debug(message, source, additional);
    }

    warn(message: any | string, ...additional: any[]): void {
        this.logger.warn(message, additional);
    }

    error(message: any | string, source: any | string, ...additional: any[]): void {
        this.logger.error(message, source, additional);
    }

    fatal(message: any | string, source: any | string, ...additional: any[]): void {
        this.logger.fatal(message, source, additional);
    }
}
